import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function BlogPost({ data }) {
  const post = data.markdownRemark

  return (
    <Layout>
      <div className="flex flex-col mt-12">
        <h1>{post.frontmatter.title}</h1>
        <small>{post.frontmatter.author} / {post.frontmatter.date}</small>
        <div dangerouslySetInnerHTML={{ __html: post.html }} className="" />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`