import React from 'react'
import { Link } from 'gatsby'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false
    };

    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this)
    this.handleMobileLinkClick = this.handleMobileLinkClick.bind(this)
  }

  handleMenuButtonClick(e) {
    this.setState(state => ({
      isActive: !state.isActive
    }));
  }

  handleMobileLinkClick(e) {
    this.setState(state => ({
      isActive: false
    }));
  }

  render() {
    const isActive = this.state.isActive;
    return (
      <div className="flex mt-8 w-full max-w-3xl m-auto">
        <Link to="/">
        <div className="flex px-4 py-2 text-white text-lg font-bold bg-gradient-to-br from-blue-600 to-green-500 hover:scale-90 transition-all ease-in-out">jp</div>
        </Link>
      </div>
    )
  }
}

export default Nav;